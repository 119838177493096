import React from 'react';
import './App.css';
import LoginComponent from './components/LoginComponent';

function App() {
  console.log('환경::', process.env.REACT_APP_HOST)
  return (
    <LoginComponent />
  );
}

if (process.env.NODE_ENV === 'development') {
  const { worker } = require('./mocks/browser');
  worker.start();
}

export default App;
