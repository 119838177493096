import { useEffect, useState } from "react";
import TextField from '@mui/material/TextField';
import { Alert, Button } from "@mui/material";
import axios from "axios";
import { ILogin, IResponse, Login } from '../bestApi';
import { setLocalStorageItem, KEY_AUTH_TOKEN, KEY_AUTH_TOKEN_EXPIRESAT, getAuthToken, removeAuthToken, } from "../utils/localStorage";
import timeAfter from "../utils/timeAfter";
import CRMDataTable from "./CRMDataTable";

const LoginComponent = () => {
  const [logFlag, setLogFlag] = useState(false);
  const [state, setState] = useState<ILogin>(
    {
      loginId: '',
      password: ''
    }
  );
  const [idError, setIdError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [textFlag, setTextFlag] = useState(0); // 0 :정상(비활성) 1: 아이디에러 2 :비번에러
  const [data, setData] = useState<Array<string | number>>([]);
  const [meta, setMeta] = useState<Array<string | number>>([]);

  const [moveFlag, setMoveFlag] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [takePage, setTakePage] = useState(10);

  const getPhotoModelApi = async(num : number, take: number) => {
    try{
      let loginToken = getAuthToken();
      const headers = {
        'Authorization': `Bearer ${loginToken}`
      };
      const getModelData = await axios.get(`${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_HOST}${process.env.REACT_APP_PHOTO_MODEL_APPLICATIONS}?order=DESC&page=${num}&take=${take}`
      ,{headers: headers});
      const orderData = getModelData.data.result.data;
      setData(orderData);
      setMeta(getModelData.data.result.meta);
      setPage(num-1);
      setTakePage(take);
    }catch(err){
      console.log(err);
    }
  };

  const handleChangePage = (event: React.ChangeEvent<HTMLInputElement>, newPage: number) => {
    //newPage는 api의 파라미터값보다 -1 작음
    setMoveFlag(true);
    getPhotoModelApi(newPage+1, takePage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    const perPage = +event.target.value;
    setRowsPerPage(perPage); // 10, 25
    // console.log(+event.target.value)
    // setPage(0);
    getPhotoModelApi(1, perPage)
  };

  const changeId = (e: { target: { value: string; }; }) => {
    setState({...state, loginId : e.target.value})

  }
  const changePassword = (e: { target: { value: string; }; }) => {
    setState({...state, password : e.target.value})
  }

  const handleLogin = async() => {
    if(state.loginId.length === 0) {
      setIdError(true);
      setTextFlag(1);
    }else if(state.password.length === 0) {
      setPasswordError(true);
      setTextFlag(2);
    }else {
      try{
        setTextFlag(0);
        setIdError(false);
        setPasswordError(false);

        const body = {
          "loginId": state.loginId,
          "password": state.password,
        }

        const loginAdmin = await axios.post(
          `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_HOST}${process.env.REACT_APP_AUTH_LOGIN}`
          , body )

        const adminData = loginAdmin.data;
        if(adminData.code === 200) {
          const token = adminData.result.token.accessToken;
          const expired = adminData.result.token.expiresIn;
          if(!logFlag){
            setLogFlag(true);
          }
          setLocalStorageItem(KEY_AUTH_TOKEN, token);
          setLocalStorageItem(KEY_AUTH_TOKEN_EXPIRESAT, String(timeAfter(expired)));

          const headers = {
            'Authorization': `Bearer ${token}`
          };
          const loginAndGetAllData = await axios.get(
            `${process.env.REACT_APP_PROTOCOL}${process.env.REACT_APP_HOST}${process.env.REACT_APP_PHOTO_MODEL_APPLICATIONS}?order=DESC&page=1&take=10`
            , {headers: headers});
       
          const orderData = loginAndGetAllData.data.result;
          setData(orderData.data);
          setMeta(orderData.meta);
        }else{
          alert('계정을 다시 확인해주세요')
        }
      }catch(err){
        console.log(err);
      }
    }
  }

  const handleLogout = () => {
    removeAuthToken();
    setLogFlag(false);
  }

  const loginForm = () => {
    return (
      <>
      <h1>Onlif 관리자 로그인</h1>
        <Alert severity='error' sx={textFlag === 0 ? {display:'none'} : {width: '90%', marginBottom: 3}}>
          { textFlag === 1 ? 'ID Error! 아이디를 다시 확인해주세요.' : 
          textFlag === 2 ? 'PWD Error! 패스워드를 다시 확인해주세요.' :  '' }
        </Alert>

        <div className='loginBox'>
          <TextField
            error={idError ? true : false}
            required
            id="outlined-required-id"
            label="관리자 아이디"
            sx={{width: '100%', marginBottom: 2}}
            onChange={changeId}
          />
          <TextField
            type="password"
            error={passwordError ? true : false}
            required
            id="outlined-required-password"
            label="관리자 패스워드"
            sx={{width: '100%', marginBottom: 3}}
            onChange={changePassword}
          />
        
        <Button onClick={handleLogin} variant="contained" sx={{width: '100%', height: 60}}>Login</Button>
        </div>
        </>
    )
  }

  useEffect(() => {
    let loginToken = getAuthToken();
    if(loginToken !== null) {
      setLogFlag(true);
      getPhotoModelApi(1,takePage)
    }else{
      loginForm();
    }
  },[logFlag])

  return(
    
    getAuthToken() !== null ? 
    
    <div style={{display: 'flex', justifyContent: 'center', height: '100vh'}}>
    <CRMDataTable
      data={data} 
      meta={meta} 
      page={page} 
      rowsPerPage={rowsPerPage} 
      handleLogout={handleLogout} 
      handleChangePage={handleChangePage} 
      handleChangeRowsPerPage={handleChangeRowsPerPage}
    /> 
    </div>
    : 
    <div className='container'>
      {loginForm()}
    </div>

  )

}

export default LoginComponent;