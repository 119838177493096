import timeExpired from './timeExpired';

export const KEY_AUTH_TOKEN_EXPIRESAT = "onlif.co.kr.auth.expiresat";
export const KEY_AUTH_TOKEN = "onlif.co.kr.auth.token";

export const getAuthToken = () => {
  const token = getLocalStorageItem(KEY_AUTH_TOKEN);
  const expiresAt = getLocalStorageItem(KEY_AUTH_TOKEN_EXPIRESAT);
  console.debug(`token:${token}`);
  console.debug(`expiresAt:${expiresAt}`);
  if(token && expiresAt) {
    if(timeExpired(expiresAt)) {
      removeLocalStorageItem(KEY_AUTH_TOKEN);
      removeLocalStorageItem(KEY_AUTH_TOKEN_EXPIRESAT);
      return null;
    }
    else {
      return token;
    }
  }
  else {
    return null;
  }
};

export const removeAuthToken = () => {
  removeLocalStorageItem(KEY_AUTH_TOKEN);
  removeLocalStorageItem(KEY_AUTH_TOKEN_EXPIRESAT);
};

export const setLocalStorageItem = (key: string, value: string) => {
  if(localStorage) {
    localStorage.setItem(key, value);
  }
};
export const getLocalStorageItem = (key: string) => {
  if(localStorage) {
    return localStorage.getItem(key);
  }
  else {
    return null;
  }
};

export const removeLocalStorageItem = (key: string) => {
  if(localStorage) {
    localStorage.removeItem(key);
  }
};