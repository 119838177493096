import React, { useState } from 'react';
import { Button, Card, Grid } from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { getAuthToken} from '../utils/localStorage';

type Props = { 
  code: string; 
  name: string; 
  phone: string; 
  birth: string; 
  subject: string; 
  location: string; 
  data: any;
  isMobile: any;
  modelSubject: string;
}

const Details = (props: Props) => {
  const { code,name,phone,birth,subject,location,data,isMobile, modelSubject } = props; // 현재 선택한 유저 정보 상태와 실제 데이터값
  // console.log('data:::',data);
  const [previewImg, setPreviewImg] = useState('');

  // 미리보기 핸들러
  const handleShowImg = (src: string) => {
    setPreviewImg(src);
  }

  // 다운로드 핸들러
  const handleDownload = (file: string | Blob) => {
    saveAs(file, 'onlif-model.png');
  }
  
  // 전체 다운로드 핸들러
  const handleAllDownload = async (postCode: string, callback: (myBase64: any) => void) => {
    type ZipData = { 
      id: number;
      front: { original: string }[]; 
      side: { original: string }[]; 
      angle: { original: string }[]; 
      selfy: { original: string }[]; 
    }
    const dataArr: Array<{ original: string }> = [];

    data?.map((el : ZipData) => {
      if(el.id === Number(postCode)) {
        el.front.map((e) => e.original.length !== 0 ? dataArr.push(e) : dataArr.push())
        el.side.map((e) => e.original.length !== 0 ? dataArr.push(e) : dataArr.push())
        el.angle.map((e) => e.original.length !== 0 ? dataArr.push(e) : dataArr.push())
        el.selfy.map((e) => e.original.length !== 0 ? dataArr.push(e) : dataArr.push())
      }
    })

    // zip파일 생성
    let zip = new JSZip();
    let blobData;
    for(let i=0; i<dataArr.length; i++) {
      blobData = await fetch(dataArr[i].original).then(r => r.blob());
      zip.file(`image_${i+1}.png`, blobData);
    };
    zip.generateAsync({type:"blob"})
    .then(function(blob) {
      // console.log(blob)
      saveAs(blob, "model.zip");
    });
  }

  if(isMobile){
    return(
      <Card variant="outlined" sx={{padding: 3}}>
        <div>
          <section style={{width: '100%',paddingRight: '20px'}}>
            <h2>회원정보</h2>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>포스트ID</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{code}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>이름</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{name}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>연락처</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{phone}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>생년월일</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{birth}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>모델분야</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{modelSubject}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>지원분야</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{subject}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>거주지</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{location}</Grid>
            </Grid>
          </section>
          <hr />
          
          <section className='userFile_mobile'>
            <h2>파일</h2>
            <Button variant="contained" startIcon={<DownloadIcon />} onClick={() => handleAllDownload(code, ((myBase64) => {
            }))}>전체 내려받기(zip)</Button>

            <h3>정면</h3>
            {
              data?.map((i: { id: number; front: string[]; }) => {
                if(code === String(i.id)) {
                  return (
                  <>
                  <Grid container sx={{padding: '10px 0px'}}>
                    {i.front.map((el :any) => {
                      let fileName = el.original;
                      fileName = fileName.split('images/');
                      const realFileName = fileName[1]
                      return (
                        el.original.length !== 0 ? 
                        <>
                          <p className='fileName_mobile '>{realFileName}</p>
                          <Button variant="outlined" onClick={() => handleShowImg(el.preview)}>보기</Button>
                          <Button variant="contained"sx={{marginLeft: '10px'}} onClick={() => handleDownload(el.original)}>내려받기</Button>
                        </>
                        :
                        <></>
                      )
                    })}
                  </Grid>
                  <hr />
                  </>
                  )
                }
              })
            }
  
            <h3>측면</h3>
            {
              data?.map((i: { id: number; front: string[]; side: string[]}) => {
                if(code === String(i.id)) {
                  return (
                  <>
                  <Grid container sx={{padding: '10px 0px'}}>
                    {i.side.map((el :any) => {
                      let fileName = el.original;
                      fileName = fileName.split('images/');
                      const realFileName = fileName[1]
                      return (
                        el.original.length !== 0 ? 
                        <>
                          <p className='fileName_mobile '>{realFileName}</p>
                          <Button variant="outlined" onClick={() => handleShowImg(el.preview)}>보기</Button>
                          <Button variant="contained"sx={{marginLeft: '10px'}} onClick={() => handleDownload(el.original)}>내려받기</Button>
                        </>
                        :
                        <></>
                      )
                    })}
                  </Grid>
                  <hr />
                  </>
                  )
                }
              })
            }
            
            <h3>45도</h3>
            {
              data?.map((i: { id: number; front: string[]; side: string[]; angle: string[]}) => {
                if(code === String(i.id)) {
                  return (
                  <>
                  <Grid container sx={{padding: '10px 0px'}}>
                    {i.angle.map((el :any) => {
                      let fileName = el.original;
                      fileName = fileName.split('images/');
                      const realFileName = fileName[1]
                      return (
                        el.original.length !== 0 ? 
                        <>
                          <p className='fileName_mobile '>{realFileName}</p>
                          <Button variant="outlined" onClick={() => handleShowImg(el.preview)}>보기</Button>
                          <Button variant="contained"sx={{marginLeft: '10px'}} onClick={() => handleDownload(el.original)}>내려받기</Button>
                        </>
                        :
                        <></>
                      )
                    })}
                  </Grid>
                  <hr />
                  </>
                  )
                }
              })
            }
  
            <h3>셀카</h3>
            {
              data?.map((i: { id: number; front: string[]; side: string[]; angle: string[], selfy: string[]}) => {
                if(code === String(i.id)) {
                  return (
                    <>
                  <Grid container sx={{padding: '10px 0px'}}>
                    {i.selfy.map((el :any) => {
                      let fileName = el.original;
                      fileName = fileName.split('images/');
                      const realFileName = fileName[1];
                      return (
                        el.original.length !== 0 ? 
                        <>
                          <p className='fileName_mobile'>{realFileName}</p>
                          <Button variant="outlined" onClick={() => handleShowImg(el.preview)}>보기</Button>
                          <Button variant="contained"sx={{marginLeft: '10px'}} onClick={() => handleDownload(el.original)}>내려받기</Button>
                        </>
                        :
                        <></>
                      )
                    })}
                  </Grid>
                  <hr />
                  </>
                  )
                }
              })
            }
          </section>
          <section className='filePreview_mobile'>
            <h2>미리보기</h2>
            <div style={{display: 'flex', width: '100%'}}>
              {
                previewImg.length === 0 ?
                <h3>선택한 이미지가 없습니다.</h3>
                :
                <img style={{width: '100%'}} alt='프리뷰이미지' id='image' src={previewImg} />
              }
              {/* <img style={{width: '100%'}} id='image' src='https://images.unsplash.com/photo-1633113088092-3460c3c9b13f?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80'/> */}
            </div>
          </section>
        </div>
      </Card>
    )
  }else{
    return(
      <Card variant="outlined" sx={{padding: 3, height: '70%'}}>
        <div style={{display: 'flex'}}>
          <section style={{width: '25%',paddingRight: '20px'}}>
            <h2>회원정보</h2>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>포스트ID</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{code}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>이름</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{name}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>연락처</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{phone}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>생년월일</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{birth}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>모델분야</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{modelSubject}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>지원분야</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{subject}</Grid>
            </Grid>
            <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
              <Grid item xs={5}>거주지</Grid>
              <Grid item xs={11} sx={{fontWeight: 700}}>{location}</Grid>
            </Grid>
          </section>
          <section className='userFile'>
            <h2>파일</h2>
            <Button variant="contained" startIcon={<DownloadIcon />} onClick={() => handleAllDownload(code, ((myBase64) => {
            }))}>전체 내려받기(zip)</Button>
  
            <h3>정면</h3>
            {
              data.map((userInfo: { id: number; front: string[]; }) => {
                if(code === String(userInfo.id)) {
                  return (
                  <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
                    {userInfo.front.map((photo :any) => {
                      // console.log(photo)
                      let fileName = photo.original;
                      fileName = fileName.split('images/');
                      const realFileName = fileName[1]
                      return (
                        photo.original.length !== 0 ? 
                        <>
                          <Grid item xs={8} key={realFileName}><p className='fileName'>{realFileName}</p></Grid>
                          <Grid item xs={8}>
                            <Button variant="outlined" onClick={() => handleShowImg(photo.preview)}>보기</Button>
                            <Button variant="contained"sx={{marginLeft: '10px'}} onClick={() => handleDownload(photo.original)}>내려받기</Button>
                          </Grid>
                        </>
                        :
                        <></>
                      )
                    })}
                  </Grid>
                  )
                }
              })
            }
  
            <h3>측면</h3>
            {
              data?.map((i: { id: number; front: string[]; side: string[]}) => {
                if(code === String(i.id)) {
                  return (
                  <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
                    {i.side.map((el :any) => {
                      let fileName = el.original;
                      fileName = fileName.split('images/');
                      const realFileName = fileName[1]
                      return (
                        el.original.length !== 0 ? 
                        <>
                          <Grid item xs={8} key={realFileName}><p className='fileName'>{realFileName}</p></Grid>
                          <Grid item xs={8}>
                            <Button variant="outlined" onClick={() => handleShowImg(el.preview)}>보기</Button>
                            <Button variant="contained"sx={{marginLeft: '10px'}} onClick={() => handleDownload(el.original)}>내려받기</Button>
                          </Grid>
                        </>
                        :
                        <></>
                      )
                    })}
                  </Grid>
                  )
                }
              })
            }
            
            <h3>45도</h3>
            {
              data?.map((i: { id: number; front: string[]; side: string[]; angle: string[]}) => {
                if(code === String(i.id)) {
                  return (
                  <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
                    {i.angle.map((el :any) => {
                      let fileName = el.original;
                      fileName = fileName.split('images/');
                      const realFileName = fileName[1]
                      return (
                        el.original.length !== 0 ? 
                        <>
                          <Grid item xs={8} key={realFileName}><p className='fileName'>{realFileName}</p></Grid>
                          <Grid item xs={8}>
                            <Button variant="outlined" onClick={() => handleShowImg(el.preview)}>보기</Button>
                            <Button variant="contained"sx={{marginLeft: '10px'}} onClick={() => handleDownload(el.original)}>내려받기</Button>
                          </Grid>
                        </>
                        :
                        <></>
                      )
                    })}
                  </Grid>
                  )
                }
              })
            }
  
            <h3>셀카</h3>
            {
              data?.map((i: { id: number; front: string[]; side: string[]; angle: string[], selfy: string[]}) => {
                if(code === String(i.id)) {
                  return (
                  <Grid container spacing={2} columns={16} sx={{padding: '10px 0px'}}>
                    {i.selfy.map((el :any) => {
                      let fileName = el.original;
                      fileName = fileName.split('images/');
                      const realFileName = fileName[1];
                      return (
                        el.original.length !== 0 ? 
                        <>
                          <Grid item xs={8} key={realFileName}><p className='fileName'>{realFileName}</p></Grid>
                          <Grid item xs={8}>
                            <Button variant="outlined" onClick={() => handleShowImg(el.preview)}>보기</Button>
                            <Button variant="contained"sx={{marginLeft: '10px'}} onClick={() => handleDownload(el.original)}>내려받기</Button>
                          </Grid>
                        </>
                        :
                        <></>
                      )
                    })}
                  </Grid>
                  )
                }
              })
            }
          </section>
          <section className='filePreview'>
            <h2>미리보기</h2>
            <div style={{display: 'flex', width: '100%'}}>
              {
                previewImg.length === 0 ?
                <h3>선택한 이미지가 없습니다.</h3>
                :
                <img style={{width: '100%'}} id='image' src={previewImg} />
              }
              {/* <img style={{width: '100%'}} id='image' src='https://images.unsplash.com/photo-1633113088092-3460c3c9b13f?ixid=MnwxMjA3fDF8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1770&q=80'/> */}
            </div>
          </section>
        </div>
      </Card>
    )
  }
}

export default Details;