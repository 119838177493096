import React, { useEffect, useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Details from './Details';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Button, Checkbox, FormControl, FormControlLabel, FormGroup, InputLabel, MenuItem, Select } from '@mui/material';
import axios from 'axios';
import { isMobile } from '../utils/mobile';
import { getAuthToken } from '../utils/localStorage';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';

interface Column {
  id: 'name' | 'id' | 'birth' | 'location' | 'phone' | 'subject' | 'modelSubject';
  label: string;
  minWidth?: number;
  align?: 'right';
  format?: (value: number) => string;
}

const columns: Column[] = [
  { 
    id: 'id', 
    label: '포스트ID', 
    minWidth: 80 
  },
  { 
    id: 'name', 
    label: '이름', 
    minWidth: 100 
  },
  {
    id: 'phone',
    label: '연락처',
    minWidth: 100,
  },
  {
    id: 'birth',
    label: '생년월일',
    minWidth: 100,
  },
  {
    id: 'modelSubject',
    label: '모델분야',
    minWidth: 100,
  },
  {
    id: 'subject',
    label: '지원분야',
    minWidth: 100,
  },
  {
    id: 'location',
    label: '거주지(시, 구)',
    minWidth: 100,
  },
];

// const columns: GridColDef[] = [
//   { field: 'id', headerName: 'ID', width: 70 },
//   { field: 'firstName', headerName: 'First name', width: 130 },
//   { field: 'lastName', headerName: 'Last name', width: 130 },
//   {
//     field: 'age',
//     headerName: 'Age',
//     type: 'number',
//     width: 90,
//   },
//   {
//     field: 'fullName',
//     headerName: 'Full name',
//     description: 'This column has a value getter and is not sortable.',
//     sortable: false,
//     width: 160,
//     valueGetter: (params: GridValueGetterParams) =>
//       `${params.getValue(params.id, 'firstName') || ''} ${
//         params.getValue(params.id, 'lastName') || ''
//       }`,
//   },
// ];

const rows = [
  { id: 1, lastName: 'Snow', firstName: 'Jon', age: 35 },
  { id: 2, lastName: 'Lannister', firstName: 'Cersei', age: 42 },
  { id: 3, lastName: 'Lannister', firstName: 'Jaime', age: 45 },
  { id: 4, lastName: 'Stark', firstName: 'Arya', age: 16 },
  { id: 5, lastName: 'Targaryen', firstName: 'Daenerys', age: null },
  { id: 6, lastName: 'Melisandre', firstName: null, age: 150 },
  { id: 7, lastName: 'Clifford', firstName: 'Ferrara', age: 44 },
  { id: 8, lastName: 'Frances', firstName: 'Rossini', age: 36 },
  { id: 9, lastName: 'Roxie', firstName: 'Harvey', age: 65 },
];
interface Data {
  name: string;
  id: string;
  birth: string;
  location: string;
  phone: string;
  subject: string;
  modelSubject: string;
}

type Files = {
  name: string,
  path: number[]
}

type Props = { 
  data: any; 
  meta: any; 
  handleLogout: any; 
  page: number; 
  rowsPerPage: any; 
  handleChangePage: any; 
  handleChangeRowsPerPage: any; 
}

const CRMDataTable = (props: Props) => {
  const {data, meta, handleLogout, page, rowsPerPage, handleChangePage, handleChangeRowsPerPage} = props;
  const [details, setDetails] = useState(false);
  const [name, setName] = useState('');
  const [id, setId] = useState('');
  const [phone, setPhone] = useState('');
  const [birth, setBirth] = useState('');
  const [location, setLocation] = useState('');
  const [subject, setSubject] = useState('');
  const [modelSubject, setModelSubject] = useState('');
  const [modelStatus, setModelStatus] = useState('');
 
  const handleMoveToDetails = (e: any) => {
    if(getAuthToken()){
      for(let i=0; i<7; i++){ // 테이블 row 길이기준
        const targetId = e.target.parentNode.children[i].id;
        const targetText = e.target.parentNode.children[i].innerHTML;
        if(targetId === 'id'){
          setId(targetText);
        }else if(targetId === 'name'){
          setName(targetText);
        }else if(targetId === 'phone'){
          setPhone(targetText);
        }else if(targetId === 'birth'){
          setBirth(targetText);
        }else if(targetId === 'location'){
          setLocation(targetText);
        }else if(targetId === 'subject'){
          setSubject(targetText);
        }else if(targetId === 'modelSubject'){
          setModelSubject(targetText)
        }else{
          alert('Error!');
        }
      }
      setDetails(true);
    }else{
      handleLogout()
    }
  };

  const handleMoveToBack = () => {
    setDetails(false);
  };

  const assignDataToTable = (totalData: { [x: string]: any; code: React.Key | null | undefined; }, data: any) => {
    // console.log(data);
    const modelDataColumn = totalData[data.id];
    if(data.id === 'modelSubject'){
      if('부분모델'){
        return '부분모델'
      }else{
        // return modelDataColumn;
        return '리얼모델';
      }
    }else{
      return modelDataColumn;
    }
  }

  const handleModelFilter = (e: { target: { value: string; }; }) => {
    setModelStatus(e.target.value)
  }

  if(isMobile()) {
    return (
      <div style={{display: 'flex', flexDirection: 'column', height: '100vh'}}>
        {!details ? 
        <>
        <div style={{margin: '10px 10px', display: 'flex', justifyContent: 'space-between'}}>
          <p style={{fontSize: '1.2rem', fontWeight: 800, marginLeft: '10px'}}>Onlif 셀카모델 신청목록</p>
          <Button onClick={handleLogout} color="info" variant="contained" sx={{height: 50}}>Logout</Button>
        </div>
        <Paper sx={{ width: '100vw', height: '100%',overflow: 'scroll'}}>
          <TableContainer>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth , backgroundColor: '#373737', color: '#fff', fontWeight: 800, }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {
                data.map((row: { [x: string]: any; code: React.Key | null | undefined; }) => {
                  return (
                    <TableRow hover role="checkbox" tabIndex={-1} key={row.code} onClick={handleMoveToDetails}>
                      {columns.map((col) => {
                        return (
                          <TableCell sx={{cursor: 'pointer'}} key={col.id} align={col.align} id={col.id}>
                            { assignDataToTable(row, col) }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            sx={{bgcolor: '#ffffff'}}
            component="div"
            count={meta.itemCount}
            rowsPerPageOptions={[10, 25]}
            page={page}
            onPageChange={handleChangePage}
            rowsPerPage={rowsPerPage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Paper>
        </>
        : 
        <div style={{width: '100vw', display: 'flex', flexDirection: 'column'}}>
          <Button variant="contained" color="info" startIcon={<ArrowBackIcon />} 
            onClick={handleMoveToBack} sx={{margin: '20px 20px'}}>
            뒤로가기
          </Button>
          <Details 
            isMobile={isMobile()}
            code={id} 
            name={name}
            phone={phone}
            birth={birth}
            location={location}
            subject={subject}
            modelSubject={modelSubject}
            data={data}
            />
        </div>
        } 
      </div>
    );
  }else{
    // return(
    //   <div style={{ height: 400, width: '100%' }}>
    //     <DataGrid
    //       rows={rows}
    //       columns={columns}
    //       pageSize={5}
    //       rowsPerPageOptions={[5]}
    //       checkboxSelection
    //     />
    //   </div>
    // );
    return (
      <div style={{display: 'flex', flexDirection: 'column'}}>
        {!details ? 
        <>
        <div style={{marginTop: '20px'}}>
          <Button onClick={handleLogout} color="info" variant="contained" sx={{width: 150, float: 'right'}}>Logout</Button>
        </div>
        <h1 style={{marginTop: '50px'}}>Onlif 셀카모델 신청목록</h1>
        <Paper sx={{ width: '80vw', overflow: 'hidden'}}>
          <TableContainer sx={{ maxHeight: '70vh', bgcolor: '#ffffff'}}>
            <Table stickyHeader aria-label="sticky table">
              <TableHead>
                <TableRow>
                  {columns.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ minWidth: column.minWidth , backgroundColor: '#373737', color: '#fff', fontWeight: 800}}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              { data.length === 0 ?
              <h3 style={{textAlign: 'center'}}>신청목록이 없습니다.</h3>
              :
              <TableBody>
                {
                  data.map((row: { [x: string]: any; code: React.Key | null | undefined; }) => {
                    console.log('row::', row);
                    return (
                      <TableRow hover role="checkbox" tabIndex={-1} key={row.id} onClick={handleMoveToDetails}>
                        {columns.map((col) => {
                          console.log('col::', col);
                          const value = row[col.id];
                          return (
                            <TableCell sx={{cursor: 'pointer'}} key={col.id} align={col.align} id={col.id}>
                              {/* { 
                                assignDataToTable(row, col)
                              } */}
                              {value}
                            </TableCell>
                          );
                        })} 
                      </TableRow>
                    );
                  })
                }
              </TableBody>
              }
            </Table>
          </TableContainer>
          <div style={{display:'flex', justifyContent:'space-between', margin: 10}}>
            <FormControl sx={{width: '120px'}}>
              {/* <InputLabel id="demo-simple-select-label">모델필터</InputLabel>
              <Select
                defaultValue='all'
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={modelStatus}
                onChange={handleModelFilter}
              >
                <MenuItem value='all'>모두</MenuItem>
                <MenuItem value='real'>리얼모델</MenuItem>
                <MenuItem value='selfy'>부분모델</MenuItem>
              </Select> */}
            </FormControl>
            <TablePagination
              sx={{bgcolor: '#ffffff'}}
              component="div"
              count={meta.itemCount}
              rowsPerPageOptions={[10, 25]}
              page={page}
              onPageChange={handleChangePage}
              rowsPerPage={rowsPerPage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </div>
        </Paper>
        </>
        : 
        <div style={{width: '80vw', height: '100vh'}}>
          <Button variant="contained" color="info" startIcon={<ArrowBackIcon />} 
            onClick={handleMoveToBack} sx={{margin: '40px 0'}}>
            뒤로가기
          </Button>
          <Details 
            isMobile={isMobile()}
            code={id} 
            name={name}
            phone={phone}
            birth={birth}
            location={location}
            subject={subject}
            modelSubject={modelSubject}
            data={data}
            />
        </div>
        } 
      </div>
    );
  }

  
};
export default CRMDataTable;
